<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex align-center justify-start">
        <app-bar-return />
        <app-bar-route />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppBarAddNew",
  components: {
    AppBarRoute: () => import("../AppBarComponents/AppBarRoute"),
    AppBarReturn: () => import("../AppBarComponents/AppBarReturn"),
  },
};
</script>
